'use client';

import { Container, Typography } from '@mui/material';
import { usePathname } from 'next/navigation';

import { Locale } from 'common/i18n/i18n.config';
import { I18nNavigation } from 'common/i18n/types';
import { ToolsVideoCompressor, ToolsVideoConverter, ToolsVideoCrop, ToolsVideoTrimmer } from 'common/routes';
import { BORDER, COLOR } from 'common/styles/colors';

import { isPathAllow } from '../../routes/SITEMAP';

interface Props {
  Navigation: I18nNavigation;
  label: string;
  lang: Locale;
}

export const HeroTools = ({ label, lang, Navigation }: Props) => {
  const pathname = usePathname();

  return (
    <Container maxWidth="lg" sx={{ borderTop: BORDER, m: '60px auto', pl: 4, pr: 4 }}>
      <Typography component="h4" sx={{ mb: 4 }} variant="h5">
        {label}
      </Typography>

      <Typography sx={{ mt: 3 }} variant="body1">
        {Navigation.VideoTrimmer && (
          <ToolsVideoTrimmer.Link
            lang={lang}
            style={{
              color: ToolsVideoTrimmer.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
              fontWeight: ToolsVideoTrimmer.isActive(lang, pathname) ? 'bold' : 'normal',
              marginRight: 16
            }}
          >
            {Navigation.VideoTrimmer}
          </ToolsVideoTrimmer.Link>
        )}

        {Navigation.VideoCompressor && (
          <ToolsVideoCompressor.Link
            lang={lang}
            style={{
              color: ToolsVideoCompressor.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
              fontWeight: ToolsVideoCompressor.isActive(lang, pathname) ? 'bold' : 'normal',
              marginRight: 16
            }}
          >
            {Navigation.VideoCompressor}
          </ToolsVideoCompressor.Link>
        )}

        {Navigation.VideoConverter && (
          <ToolsVideoConverter.Link
            lang={lang}
            style={{
              color: ToolsVideoConverter.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
              fontWeight: ToolsVideoConverter.isActive(lang, pathname) ? 'bold' : 'normal',
              marginRight: 16
            }}
          >
            {Navigation.VideoConverter}
          </ToolsVideoConverter.Link>
        )}

        {isPathAllow('VideoCropPage', lang) && (
          <ToolsVideoCrop.Link
            lang={lang}
            style={{
              color: ToolsVideoCrop.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
              fontWeight: ToolsVideoCrop.isActive(lang, pathname) ? 'bold' : 'normal',
              marginRight: 16
            }}
          >
            {Navigation.VideoCrop}
          </ToolsVideoCrop.Link>
        )}
      </Typography>
    </Container>
  );
};
