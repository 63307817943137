import { Locale } from '../i18n/i18n.config';

import { PATHS } from './PATHS';

export const SITEMAP = {
  EditorPage: {
    changeFrequency: 'weekly',
    match: (lang: Locale, path: string) => PATHS.Editor[lang] === path || path.startsWith(PATHS.Editor[lang]),
    path: PATHS.Editor,
    priority: 0.9
  },
  HomePage: {
    changeFrequency: 'weekly',
    match: (lang: Locale, path: string) => PATHS.Home[lang] === path,
    path: PATHS.Home,
    priority: 1
  },
  PrivacyPolicyPage: {
    changeFrequency: undefined,
    match: (lang: Locale, path: string) => PATHS.PrivacyPolicy[lang] === path,
    path: PATHS.PrivacyPolicy,
    priority: 0.1
  },
  TermsOfUsePage: {
    changeFrequency: undefined,
    match: (lang: Locale, path: string) => PATHS.TermsOfUse[lang] === path,
    path: PATHS.TermsOfUse,
    priority: 0.1
  },
  ToolsPage: {
    changeFrequency: 'weekly',
    match: (lang: Locale, path: string) => PATHS.Tools[lang] === path,
    path: PATHS.Tools,
    priority: 0.9
  },
  VideoCompressorPage: {
    changeFrequency: 'weekly',
    match: (lang: Locale, path: string) => PATHS.VideoCompressor[lang] === path,
    path: PATHS.VideoCompressor,
    priority: 0.8
    // supportedLocales: ['en', 'it', 'fr', 'de', 'es'] as Locale[]
  },
  VideoConverterPage: {
    changeFrequency: 'weekly',
    match: (lang: Locale, path: string) => PATHS.VideoConverter[lang] === path,
    path: PATHS.VideoConverter,
    priority: 0.8
  },
  VideoCropPage: {
    changeFrequency: 'weekly',
    match: (lang: Locale, path: string) => PATHS.VideoCrop[lang] === path,
    path: PATHS.VideoCrop,
    priority: 0.8,
    supportedLocales: ['en', 'it'] as Locale[] //, 'fr', 'de', 'es', 'pt'
  },
  VideoTrimmerPage: {
    changeFrequency: 'weekly',
    match: (lang: Locale, path: string) => PATHS.VideoTrimmer[lang] === path,
    path: PATHS.VideoTrimmer,
    priority: 0.8
  }
} as const;

export const isPathAllow = (path: keyof typeof SITEMAP, lang: Locale) => {
  const map = SITEMAP[path];
  if ('supportedLocales' in map) {
    return map.supportedLocales.includes(lang);
  }

  return true;
};
